import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang
import elementZhTwLocale from 'element-ui/lib/locale/lang/zh-TW'
import elementViVNLocale from 'element-ui/lib/locale/lang/vi'
import en from './en'
import zhHans from './zh-hans'
import zhHanT from "./zh-hant";
import vi from "./vi-VN";

Vue.use(VueI18n)

const messages = {
  en: {
    ...en,
    ...elementEnLocale
  },
  zhHans: {
    ...zhHans,
    ...elementZhLocale
  },
  zhHanT: {
    ...zhHanT,
    ...elementZhTwLocale
  },
  vi: {
    ...vi,
    elementViVNLocale
  }

}
export function getLanguage() {
  const chooseLanguage = localStorage.getItem('language')
  if (chooseLanguage) return chooseLanguage
  return null
}


const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages
})

export default i18n
