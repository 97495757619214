import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import i18n from '@/lang/index';

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        return new Promise((resolve, reject) => {
            if (store.getters.token) {
                config.headers['Authorization'] = `Bearer ${getToken()}`;
            }
            if (config.init) {
                resolve(config);
            }
            const maxWaitTime = 30000;
            const startTime = Date.now();
            const checkData = () => {
                if (store.getters.shopID && store.getters.TenantId) {
                    try {
                        config.data['TenantId'] = store.getters.TenantId
                        config.data['ShopId'] = store.getters.shopID
                    } catch(e) {
                     console.log(e)
                    }
                    resolve(config);
                } else if (Date.now() - startTime < maxWaitTime) {
                    setTimeout(checkData, 300);
                } else {
                    reject(new Error('Timeout waiting for target data'));
                }
            };
            checkData();
        })
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        // if the custom code is not 20000, it is judged as an error.
        if (res.Code && res.Code !== 200) {
            Message({
                message: res.Msg || 'Error',
                type: 'error',
                duration: 5 * 1000
            })

            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    error => {
        if (error.response.status === 401) {
            MessageBox.confirm(i18n.t('utils.message'), i18n.t('utils.title'), {
                confirmButtonText: i18n.t('utils.confirm'),
                cancelButtonText: i18n.t('utils.cancel'),
                type: 'warning'
            }).then(() => {
                store.dispatch('user/resetToken').then(() => {
                    location.reload()
                }).catch(() => {
                    store.dispatch('user/logout')
                })
            }).catch(() => {
                store.dispatch('user/logout')
            })
        } else {
            Message({
                message: error.message,
                type: 'error',
                duration: 5 * 1000
            })
        }
        return Promise.reject(error)
    }
)

export default service
