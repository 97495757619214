<template>
  <div class="container" v-show="isShow">
    <div class="upper">
      <div class="box">
        <div class="box-item">
          <download></download>
        </div>
        <div class="box-item">
          <div class="title-item" @click="jumpPage('/procedure-domestic', true)">{{ $t('footer.domesticConsolidation') }}</div>
          <div class="title-item">{{ $t('footer.domesticDeliveryService') }}</div>
          <div class="title-item">{{ $t('footer.domesticStorePickup') }}</div>
          <div class="title-item" @click="jumpPage('/service/about-Us', true)">{{ $t('footer.more') }}</div>
        </div>
        <div class="box-item">
          <div class="title-item" @click="jumpPage('/consolidation/teach', true)">{{ $t('footer.noviceTeach') }}</div>
          <div class="title-item" @click="jumpPage('/consolidation/problem', true)">{{ $t('footer.frequentlyQuestion') }}</div>
          <div class="title-item" @click="jumpPage('/consolidation/shipping-calculation', true)">{{ $t('footer.freightCalculation') }}</div>
          <div class="title-item" @click="jumpPage('/consolidation/problem', true)">{{ $t('footer.more') }}</div>
        </div>
        <div class="box-item">
          <div class="title-item" @click="jumpPage(`/help/rich?id=${richId}`, true)">{{ $t('footer.earnPoints') }}</div>
<!--          <div class="title-item" @click="jumpPage('/help/rich?id=14', true)">{{ $t('footer.cashRoll') }}</div>-->
<!--          <div class="title-item" @click="jumpPage('/help/rich?id=13', true)">{{ $t('footer.payment') }}</div>-->
          <div class="title-item" @click="jumpPage(`/help/rich?id=${richId}`, true)">{{ $t('footer.more') }}</div>
        </div>
      </div>
    </div>
    <div class="lower">
      <div class="lower-item">
        <div class="title-item" @click="jumpPage('/service/about-Us', true)">{{ $t('footer.companyProfile') }}</div>
        <div class="title-item" @click="jumpPage('/service/privacy', true)">{{ $t('footer.declarationProtection') }}</div>
        <div class="title-item" @click="jumpPage('/service/term', true)">{{ $t('footer.disclaimers') }}</div>
        <div class="title-item" @click="jumpPage('/service/carriage-treaty', true)">{{ $t('footer.acceptCarriage') }}</div>
        <div class="title-item" @click="jumpPage('/service/contact-Us', true)">{{ $t('footer.contactUs') }}</div>
        <div class="title-item">{{ $t('footer.filings') }}</div>
        <div class="title-item" @click="toSearch">赣ICP备2023009463号-1</div>
      </div>
    </div>
  </div>
</template>

<script>
import Download from './Download.vue'
export default {
  components: { Download },
  data() {
    return {
      richId: undefined
    }
  },
  computed: {
    isShow() {
      return this.$route.path.indexOf('member') === -1
    }
  },
  mounted() {
    this.$store.dispatch('webSite/getShopMatters', 2).then(response => {
      this.richId = response[0].Id
    })
  },
  methods: {
    jumpPage(path, scrollTo=false) {
      if (scrollTo) {
        window.scrollTo(0, 0);
      }
      this.$router.push({ path: path})
    },
    toSearch() {
      window.open('https://beian.miit.gov.cn/', '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  //height: 36rem;
  .upper {
    width: 100%;
    height: 24rem;
    background-color: #093267;
    display: flex;
    justify-content: center;
    align-items: center;
    .box {
      display: flex;
      justify-content: space-evenly;
      align-items: flex-start;
      color: white;
      font-size: 1.2rem;
      font-family: PingFang SC;
      .box-item {
        margin: 0 5rem;
        .title-item {
          padding: 0.8rem 0;
          cursor: pointer;
        }
        .title-item:hover {
          color: yellow;
        }
      }
    }
  }
  .lower {
    width: 100%;
    height: 5rem;
    background-color: #031833;
    position: relative;
    .lower-item {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 50%);
      bottom: 50%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      color: white;
      font-size: 1.2rem;
      font-weight: 500;
      .title-item {
        padding: 0 1.5rem;
        white-space: nowrap;
        cursor: pointer;
      }
    }
  }
}
</style>
