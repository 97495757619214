import i18n from '@/lang'
import store from "@/store";

export default function getPageTitle(key) {
    const hasKey = i18n.te(`route.${key}`)
    if (hasKey) {
        const pageName = i18n.t(`route.${key}`)
        return `${pageName} - ${store.getters.shop_name}`
    }
    return `${store.getters.shop_name}`
}
