<template>
  <div class="container">
    <div class="ipt-box u-between-flex">
      <div class="introduce-box animated bounceInDown">
        <div class="introduce-title mb30">{{ shop_name }}</div>
        <div class="introduce-line mb30"></div>
        <div class="introduce-sub-title mt40 mb30">{{ $t('login.subTitle1') }}</div>
<!--        <div class="introduce-small-title mt20 mb10">{{ $t('login.subTitle2') }}</div>-->
        <div class="introduce-small-title mb10">{{ $t('login.subTitle3') }}</div>
        <div class="introduce-small-title">{{ $t('login.subTitle4') }}</div>
      </div>
      <div class="login-box animated flipInY">
        <div class="login-title">{{ $t('login.login') }}</div>
        <div class="login-form">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item label="" prop="username">
              <el-input v-model="ruleForm.username" style="width: 32rem;" size="small" :placeholder="$t('login.account')"></el-input>
            </el-form-item>
            <el-form-item label="" prop="password">
              <el-input v-model="ruleForm.password" style="width: 32rem;" size="small" show-password :placeholder="$t('login.password')"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="login-ability u-between-flex">
          <div @click="$router.push({path: '/register'})" class="pointer" style="font-size: 1.4rem; color: #333333;">{{ $t('login.register') }}</div>
          <div class="pointer" style="font-size: 1.4rem; color: #999999">{{ $t('login.forgetPassword') }}</div>
        </div>
        <div class="mt20 mb10">
          <el-button :loading="loading" class="login-btn" @click="submitForm('ruleForm')">{{ $t('login.submit') }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  data() {
    return {
      ruleForm: {
        username: undefined,
        password: undefined
      },
      loading: false
    }

  },
  created() {
    const t = this;
    document.onkeydown = function(e){
      let key;
      if(window.event == undefined){
        key = e.keyCode;
      }else{
        key = window.event.keyCode;
      }
      //enter的ASCII码是13
      if(key === 13){
        t.submitForm('ruleForm');
      }
    }
  },
  computed: {
    ...mapGetters(['shop_name']),
    rules() {
      return {
        username: [
          { required: true, message: this.$t('login.placeholderAccount'), trigger: 'blur' },
        ],
        password: [
          { required: true, message: this.$t('login.placeholderPassword'), trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store.dispatch('user/login', this.ruleForm).then(() => {
            this.$store.commit('user/SET_SHOW_DIALOG', true)
            this.$router.push({
              path: '/member/personal'
            })
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}

</script>

<style scoped lang="scss">
.container {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-image: url("../../static/images/bgc.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .ipt-box {
    position: absolute;
    top: 20%;
    left: 15%;
    right: 20%;
    .introduce-box {
      .introduce-title {
        font-size: 6rem;
        color: #FFFFFF;
      }
      .introduce-line {
        width: 60rem;
        height: 0.125rem;
        background-color: #fff;
      }
      .introduce-sub-title {
        font-size: 4.8rem;
        color: #FFFFFF;
      }
      .introduce-small-title {
        font-size: 1.6rem;
        color: #FFFFFF;
      }
    }
    .login-box {
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 0.8rem;
      padding: 2.5rem;
      .login-title {
        font-size: 2rem;
        color: #333333;
        border-bottom: 0.1rem solid #999999;
        padding-bottom: 1rem;
        margin-bottom: 1.5rem;
      }
      .login-btn {
        width: 32rem;
        height: 4.8rem;
        background: linear-gradient(to bottom right, #093267, #3F63EC);
        border-radius: 0.8rem;
        color: #fff;
        font-size: 1.8rem;
        vertical-align: center;
      }
    }
  }
}
</style>
