<template>
  <div id="right-box">
    <div class="box" v-show="isHoldShow">
      <span class="hvr-bounce-to-left"
             :class="item.type === 1 ? 'span-lxdh' : 'span-wxlx'"
             @mouseleave="handleLeave(item.name)"
             @mouseenter="handleEnter(item.name)"
             v-for="(item, i) in rightBox"
             :key="item.id"
       >
        <img :src="item.icon" alt="" :title="item.name" />
        <p>{{ item.name }}</p>
        <div v-if="item.type === 1" @click="copyToClipboard(item.text)" v-show="isShow[item.name]" :style="computedFixed(item.type, i)" class="wxlx animated bounceInLeft">{{ item.text }}</div>
        <div v-else v-show="isShow[item.name]" :style="computedFixed(item.type)"  class="wxlx animated bounceInLeft">
          <img :src="item.image" alt="" />
          <p v-for="str in splitText(item.text)" :key="str">{{ str }}</p>
        </div>
       </span>
      <span class="hvr-bounce-to-top" @click="toTop" v-show="showBackToTop">
      <img src="@/static/images/rightBox/dbtb.png" alt="" title="回到顶部" />
      <p>TOP</p>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "",
  data() {
    return {
      showBackToTop: false,
      boxStyle: false,
      isShow: {
        "微信公众号": false
      }
    };
  },
  computed: {
    ...mapGetters(['rightBox']),
    isHoldShow() {
      return this.$route.path.indexOf('member') === -1
    }
  },
  methods: {
    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.$message.success(this.$t('account.copySuccess'))
      } catch (err) {
        console.log(err)
      }
    },
    splitText(str) {
      return str.split(/[\s\n]+/);
    },
    handleScroll() {
      // 判断页面滚动距离是否大于500px
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      this.showBackToTop = scrollTop > 500;
    },
    handleLeave(name) {
      this.isShow[name] = false
      this.$forceUpdate()
    },
    handleEnter(name) {
      this.isShow[name] = true
      this.$forceUpdate()
    },
    computedFixed(type) {
      if (type === 1) {
        return {
          position: "absolute",
          right: "-164px",
          width: "164px",
          height: "auto",
          lineHeight: "70px",
          textAlign: "center",
          fontSize: "18px",
          color: "#fff",
          background: "#007fff",
          // top: `${ index * 73 }px`
          top: 0
        }
      } else {
        return {
          position: "absolute",
          right: "-164px",
          width: "164px",
          height: "190px",
          textAlign: "center",
          fontSize: "18px",
          color: "#fff",
          background: "#007fff",
          // top: `${ index * 73 - 60 }px`
          top: '-60px'
        }
      }
    },
    toTop() {
      const scrollDuration = 500; // 滚动到顶部的时间，单位毫秒
      const scrollStep = -window.scrollY / (scrollDuration / 15);
      const scrollInterval = setInterval(() => {
        if (window.scrollY !== 0) {
          window.scrollBy(0, scrollStep);
        } else {
          clearInterval(scrollInterval);
        }
      }, 15);
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    $route: {
      handler() {
        this.boxStyle = this.$router.history.current.name === "index";
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="css">
.box {
  position: fixed;
  left: 3px;
  top: 25%;
  height: 292px;
  width: 70px;
  text-align: center;
  z-index: 9999;
}
.box span {
  display: inline-block;
  margin-bottom: 3px;
  height: 70px;
  width: 70px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.6);
}
.box img {
  height: 30px;
  line-height: 30px;
  margin-top: 7px;
  position: relative;
}
.box p {
  position: relative;
  color: #fff;
  font-size: 12px;
  margin-top: 5px;
  line-height: 20px;
}

.hvr-bounce-to-left {
  position: relative;
}

.hvr-bounce-to-left::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #007fff;
  transition: width 1s;
}

.hvr-bounce-to-left:hover::before {
  width: 100%;
}


.hvr-bounce-to-top {
  position: relative;
}

.hvr-bounce-to-top::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  background-color: #007fff;
  transition: height 1s;
}

.hvr-bounce-to-top:hover::before {
  height: 100%;
}

.wxlx {
}
.wxlx img {
  width: 118px;
  height: 118px;
  margin-top: 15px;
}
.wxlx p {
  margin-bottom: 0;
  font-size: 16px;
}
</style>
