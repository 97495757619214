<template>
  <div class="container">
    <div class="btn-box">
      <button class="down-btn" @click="isIos=true">{{ $t('download.ios') }}</button>
      <button class="down-btn" @click="isIos=false">{{ $t('download.android') }}</button>
    </div>
    <div class="qrcode" :class="isIos ? 'qrcode-ios' : 'qrcode-android'">
      <canvas ref="qrcodeCanvas" :style="canvasStyle"></canvas>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode'
export default {
  data() {
    return {
      isIos: false,
      canvasStyle: {}
    }
  },
  methods: {
    generateQRCode(url) {
      const canvas = this.$refs.qrcodeCanvas
      QRCode.toCanvas(canvas, url, (error) => {
        if (error) {
          console.error(error)
        } else {
          this.canvasStyle = {
            width: '14rem',
            height: '14rem'
          }
          this.$forceUpdate()
        }
      })
    }
  },
  mounted() {
    const url = 'https://example.com' // 替换为您想要生成二维码的URL
    this.generateQRCode(url)
  }
}
</script>

<style scoped lang="scss">
.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .btn-box {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    height: 14rem;
    justify-content: space-between;
    .down-btn {
      background: #3F63EC;
      width: 18rem;
      height: 5rem;
      border-radius: 2.5rem;
      color: #ffffff;
      font-size: 1.6rem;
      border: none;
      cursor: pointer;
    }
  }
  .qrcode-android {
    width: 14rem !important;
    height: 14rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .qrcode-ios {
    width: 14rem !important;
    height: 14rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .qrcode-ios::before {
    content: "";
    position: absolute;
    background-color: #FFF; /* 与矩形的背景颜色相同 */
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 1px;
    left: -1.5rem;
    top: 2.5rem;
    transform: translate(50%, -50%) rotateZ(45deg);
  }

  .qrcode-android::after {
    content: "";
    position: absolute;
    background-color: #FFF; /* 与矩形的背景颜色相同 */
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 1px;
    left: -1.5rem;
    bottom: 2.5rem;
    transform: translate(50%, 50%) rotateZ(45deg);
  }
}
</style>
