<template>
  <div>
    <Headers></Headers>
    <transition name="slide-left">
      <router-view v-if="!loading"></router-view>
    </transition>
    <Footer></Footer>
    <right-box></right-box>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Headers from "@/components/Headers.vue";
import { mapGetters } from "vuex"
import RightBox from "@/components/RightBox.vue";
export default {
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(['token'])
  },
  created() {
    this.init()
  },
  components: {
    Footer,
    Headers,
    RightBox
  },
  methods: {
    init() {
      if (this.token) {
        this.loading = true
        this.$store.dispatch('user/getInfo').then(() => {
          this.loading = false
        })
      }
    }
  },
}

</script>

<style lang="scss" scoped>
.slide-left-enter-active, .slide-left-leave-active, .slide-right-enter-active, .slide-right-leave-active {
  transition: transform 0.3s ease;
}
.slide-left-enter, .slide-right-leave-to /* .slide-left-leave-active in <2.1.8 */ {
  transform: translateX(100%);
}
.slide-right-enter, .slide-left-leave-to /* .slide-right-leave-active in <2.1.8 */ {
  transform: translateX(-100%);
}
</style>
