<template>
  <div class="tooltip-container">
    <span class="text" :style="{ color: internal ? 'white' : 'black' }">
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 95 114"
        class="svgIcon"
    >
      <rect :style="{ fill : internal ? 'white' : 'black'}" rx="28.5" height="57" width="57" x="19"></rect>
      <path
          :style="{ fill : internal ? 'white' : 'black'}"
          d="M0 109.5C0 83.2665 21.2665 62 47.5 62V62C73.7335 62 95 83.2665 95 109.5V114H0V109.5Z"
      ></path>
    </svg>
    {{ userInfo.Name }}</span
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  computed: {
    ...mapGetters(['userInfo', 'internal'])
  }
}

</script>

<style scoped lang="scss">
.tooltip-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0);
  padding: 11px 18px;
  border-radius: 12px;
  cursor: pointer;
  border: 1px solid rgba(211, 211, 211, 0);
}

.text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(0, 0, 0);
}
.svgIcon {
  width: 16px;
  height: auto;
}

</style>