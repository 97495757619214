import request from "@/utils/requests"

export function CountryInfo(data) {
    return request({
        url: '/Base_Destination/GetDataCodePage',
        method: 'post',
        data
    })
}


export function getCountry(data) {
    return request({
        url: '/Base_Destination/GetDataPage',
        method: 'post',
        data
    })
}

export function computedVolume(data) {
    return request({
        url: '/OrderCalculateFee/ShopVolumeBudget',
        method: 'post',
        data
    })
}

export function getProvince(data) {
    return request({
        url: '/Base_Province/GetDataPage',
        method: 'post',
        data
    })
}

export function getCity(data) {
    return request({
        url: '/Base_City/GetDataPage',
        method: 'post',
        data
    })
}

export function getArea(data) {
    return request({
        url: '/Base_Area/GetDataPage',
        method: 'post',
        data
    })
}

export function GetShopInfo(data) {
    return request({
        url: '/Base_Shop/GetShopInfo',
        method: 'post',
        init: true,
        data
    })
}

export function parcelForecastSingle(data) {
    return request({
        url: '/ParcelOperate/BatchParcelForecastSingle',
        method: 'post',
        data
    })
}


export function batchParcelForecast(data) {
    return request({
        url: '/ParcelOperate/BatchParcelForecast',
        method: 'post',
        data
    })
}


export function getGoodsType(data) {
    return request({
        url: '/Base_GoodsType/GetDataPage',
        method: 'post',
        data
    })
}


export function getWareHouses(data) {
    return request({
        url: '/Base_WareHouse/GetDataPage',
        method: 'post',
        data
    })
}


export function getCarrier(data) {
    return request({
        url: '/Base_Carrier/GetDataPage',
        method: 'post',
        data
    })
}

export function getTimer(data) {
    return request({
        url: '/Base_Timer/GetDataPage',
        method: 'post',
        data
    })
}

export function getAdded(data) {
    return request({
        url: '/Base_AddedService/GetDataPage',
        method: 'post',
        data
    })
}


export function getAddedList(data) {
    return request({
        url: '/Base_AddedServiceDetail/GetDataPage',
        method: 'post',
        data
    })
}


export function Payment(data) {
    return request({
        url: '/Pay/ZBPay',
        method: 'post',
        data
    })
}


export function QrCodePayState(data) {
    return request({
        url: '/Internal/QueryPayState',
        method: 'post',
        data
    })
}

export function getShopImage(data) {
    return request({
        url: '/Base_Shop/GetShopImage',
        method: 'post',
        data
    })
}


export function getNotices(data) {
    return request({
        url: '/Base_Proclamation/GetDataPage',
        method: 'post',
        data
    })
}


export function getNoticeDetail(params) {
    return request({
        url: '/Base_Proclamation/GetDataDetail',
        method: 'get',
        params
    })
}


export function getShopMatters(data) {
    return request({
        url: '/Base_ShopMatters/GetDataPage',
        method: 'post',
        data
    })
}


export function getPayTypes(data) {
    return request({
        url: '/Base_ShopPayType/GetDataPage',
        method: 'post',
        data
    })
}

export function queryTrackLog(data) {
    return request({
        url: '/OrderTrackLog/GetOrderTrackLogInfo',
        method: 'post',
        data
    })
}


export function getShopVideo(data) {
    return request({
        url: '/Base_ShopVideo/GetDataPage',
        method: 'post',
        data
    })
}


export function getShopEvents(data) {
    return request({
        url: '/Base_ShopEvents/GetDataPage',
        method: 'post',
        data
    })
}


export function getIntroduces(data) {
    return request({
        url: '/Base_ShopQuote/GetFeeIntroduction',
        method: 'post',
        data
    })
}

export function getComment(data) {
    return request({
        url: '/Order_ReviewsLog/GetDataPage',
        method: 'post',
        data
    })
}


export function getWalletFlow(data) {
    return request({
        url: '/Client_Wallet/GetClientWalletStatistics',
        method: 'post',
        data
    })
}


export function uploadImage(data) {
    return request({
        url: '/File/ImgUpload',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}


export function getConfig(params) {
    return request({
        url: '/Base_SystemConfig/GetSystemConfig',
        method: 'get',
        params
    })
}


export function getCurrentMoney(data) {
    return request({
        url: '/Base_ShopPayType/GetPayCurrencyMoney',
        method: 'post',
        data
    })
}


export function downloadTemplate() {
    return request({
        url: '/Order/WebOrderImportExcelTemplate',
        method: 'get',
        responseType: 'blob'
    })
}

export function validateTemplate(data) {
    return request({
        url: '/Order/OrderImportVerification',
        method: 'post',
        data
    })
}


export function uploadOrder(data) {
    return request({
        url: '/Order/OrderImport',
        method: 'post',
        data
    })
}


export function printOrder(data) {
    return request({
        url: '/OrderOperate/GetPrintSheet',
        method: 'post',
        data
    })
}


export function getPdfByte(data) {
    return request({
        url: '/Order/DedicatedOrderClientPrint',
        method: 'post',
        data
    })
}