import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router';
import './static/style/common.css'
import store from './store'
import moment from 'moment';
import i18n from './lang'
import 'animate.css'
// 在main.js中全局引入
import VideoPlayer from 'vue-video-player/src';
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import scroll from 'vue-seamless-scroll'
import Print from 'vue-print-nb'
Vue.use(Print)
Vue.use(scroll)
import vueJsonExcelUmd from "vue-json-excel";
Vue.component("downloadExcel", vueJsonExcelUmd);

Vue.use(VideoPlayer)

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})
// 创建一个全局 Vue filter
Vue.filter('formatDate', function (value, format = 'YYYY-MM-DD HH:mm:ss') {
  if (!value) return '';
  return moment(value).format(format);
});

Vue.config.productionTip = false

async function initApp() {
    // 启动应用
    new Vue({
      router,
      store,
      i18n,
      render: h => h(App),
      created() {
        setRemPc();
        window.addEventListener('resize', setRemPc);
      }
    }).$mount('#app')
}


//rem计算
function setRemPc() {
  const whdef = 10 / 1920;// 表示1920的设计图,使用100PX的默认值
  const bodyWidth = document.body.clientWidth;// 当前窗口的宽度
  const rem = bodyWidth * whdef;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
}

initApp();
